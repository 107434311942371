var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"user"},[_c('div',{staticClass:"title"},[_c('PlateTitle',{staticClass:"plate",attrs:{"title":"企业信息"}})],1),_c('a-spin',{attrs:{"spinning":_vm.spinUser}},[_c('div',{staticClass:"main"},[_c('div',{staticClass:"tips"},[_c('div',[_vm._v(" 温馨提示：上传文件大小不能超过5MB！ ")]),_c('div',[_vm._v(" 请如实填写企业信息，企业信息错误导致的保函失效将由投保企业自行承担! ")])]),_c('a-form',{attrs:{"form":_vm.form,"label-col":{ span: 4 },"wrapper-col":{ span: 8 }}},[_c('a-row',[_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"企业名称","has-feedback":""}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'companyName',
                  { rules: [{ required: true, message: '请输入企业名称' }] } ]),expression:"[\n                  'companyName',\n                  { rules: [{ required: true, message: '请输入企业名称' }] },\n                ]"}],staticStyle:{"width":"280px"},attrs:{"placeholder":"请输入企业名称"}})],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"统一社会信用代码","has-feedback":""}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'creditCode',
                  {
                    rules: [
                      { required: true, message: '请输入统一社会信用代码' } ],
                  } ]),expression:"[\n                  'creditCode',\n                  {\n                    rules: [\n                      { required: true, message: '请输入统一社会信用代码' },\n                    ],\n                  },\n                ]"}],staticStyle:{"width":"280px"},attrs:{"placeholder":"请输入统一社会信用代码"}})],1)],1)],1),_c('a-row',[_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"基本户账号","has-feedback":""}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'account',
                  {
                    rules: [
                      { required: true, message: '请输入基本户账号' },
                      { validator: _vm.validateNumber }
                    ],
                  } ]),expression:"[\n                  'account',\n                  {\n                    rules: [\n                      { required: true, message: '请输入基本户账号' },\n                      { validator: validateNumber }\n                    ],\n                  },\n                ]"}],staticStyle:{"width":"280px"},attrs:{"placeholder":"请输入基本户账号"}})],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"基本户开户行","has-feedback":""}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'bankNo',
                  {
                    rules: [{ required: true, message: '请输入基本户开户行' }],
                  } ]),expression:"[\n                  'bankNo',\n                  {\n                    rules: [{ required: true, message: '请输入基本户开户行' }],\n                  },\n                ]"}],staticStyle:{"width":"280px"},attrs:{"placeholder":"请输入基本户开户行"}})],1)],1)],1),_c('a-row',[_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"企业类型","has-feedback":""}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'companyType',
                  { rules: [{ required: true, message: '请输入企业类型' }] } ]),expression:"[\n                  'companyType',\n                  { rules: [{ required: true, message: '请输入企业类型' }] },\n                ]"}],staticStyle:{"width":"280px"},attrs:{"placeholder":"请输入企业类型"}},_vm._l((_vm.certTypeList),function(item,index){return _c('a-select-option',{key:index,attrs:{"value":item.value}},[_vm._v(" "+_vm._s(item.label)+" ")])}),1)],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"注册资本(万元)","has-feedback":""}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'registeredCapital',
                  { rules: [{ required: true, message: '请输入注册资本' }] } ]),expression:"[\n                  'registeredCapital',\n                  { rules: [{ required: true, message: '请输入注册资本' }] },\n                ]"}],staticStyle:{"width":"280px"},attrs:{"placeholder":"请输入注册资本"}})],1)],1)],1),_c('a-row',[_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"企业地址","has-feedback":""}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'appCompanyAddress',
                  { rules: [{ required: true, message: '请输入企业地址' }] } ]),expression:"[\n                  'appCompanyAddress',\n                  { rules: [{ required: true, message: '请输入企业地址' }] },\n                ]"}],staticStyle:{"width":"280px"},attrs:{"placeholder":"请输入企业地址"}})],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"法人姓名","has-feedback":""}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'legalName',
                  { rules: [{ required: true, message: '请输入法人姓名' }] } ]),expression:"[\n                  'legalName',\n                  { rules: [{ required: true, message: '请输入法人姓名' }] },\n                ]"}],staticStyle:{"width":"280px"},attrs:{"placeholder":"请输入法人姓名"}})],1)],1)],1),_c('a-row',[_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"法人身份证号码","has-feedback":""}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'legalCertNo',
                  {
                    rules: [
                      { required: true, message: '请输入法人身份证号码' } ],
                  } ]),expression:"[\n                  'legalCertNo',\n                  {\n                    rules: [\n                      { required: true, message: '请输入法人身份证号码' },\n                    ],\n                  },\n                ]"}],staticStyle:{"width":"280px"},attrs:{"placeholder":"请输入法人身份证号码"}})],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"法人手机号","has-feedback":""}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'legalPhone',
                  {
                    rules: [{ required: true, message: '请输入法人手机号' }],
                  } ]),expression:"[\n                  'legalPhone',\n                  {\n                    rules: [{ required: true, message: '请输入法人手机号' }],\n                  },\n                ]"}],staticStyle:{"width":"280px"},attrs:{"placeholder":"请输入法人手机号"}})],1)],1)],1),_c('a-row',[_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{staticClass:"formItem",attrs:{"label":"营业执照"}},[_c('a-form-item',{staticClass:"items"},[_c('a-upload',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    'businessLicenseImage',
                    { rules: [{ validator: _vm.compareFile1 }] } ]),expression:"[\n                    'businessLicenseImage',\n                    { rules: [{ validator: compareFile1 }] },\n                  ]"}],attrs:{"name":"file","multiple":false,"action":_vm.uploadUrl,"headers":_vm.headers,"file-list":_vm.businessLicenseImage,"accept":".jpg,.png"},on:{"change":function (info) { return _vm.handleChange(info, '1'); }}},[_c('a-button',{staticStyle:{"margin-right":"50px"}},[_vm._v(" "+_vm._s(_vm.businessLicenseImage.length > 0 ? "重新上传" : "点击上传")+" ")]),(_vm.businessLicenseImage.length > 0)?_c('a-button',{on:{"click":function($event){return _vm.download('1')}}},[_vm._v(" 点击预览 ")]):_vm._e()],1)],1)],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{staticClass:"formItem",attrs:{"label":"法人身份证正面"}},[_c('a-form-item',{staticClass:"items"},[_c('a-upload',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    'legalPersonCardImage',
                    { rules: [{ validator: _vm.compareFile2 }] } ]),expression:"[\n                    'legalPersonCardImage',\n                    { rules: [{ validator: compareFile2 }] },\n                  ]"}],attrs:{"name":"file","multiple":false,"action":_vm.uploadUrl,"headers":_vm.headers,"file-list":_vm.legalPersonCardImage,"accept":".jpg,.png"},on:{"change":function (info) { return _vm.handleChange(info, '2'); }}},[_c('a-button',{staticStyle:{"margin-right":"50px"}},[_vm._v(" "+_vm._s(_vm.legalPersonCardImage.length > 0 ? "重新上传" : "点击上传")+" ")]),(_vm.legalPersonCardImage.length > 0)?_c('a-button',{on:{"click":function($event){return _vm.download('2')}}},[_vm._v(" 点击预览 ")]):_vm._e()],1)],1)],1)],1)],1),_c('a-row',[_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{staticClass:"formItem",attrs:{"label":"法人身份证反面"}},[_c('a-form-item',{staticClass:"items"},[_c('a-upload',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    'legalPersonCardImage2',
                    { rules: [{ validator: _vm.compareFile3 }] } ]),expression:"[\n                    'legalPersonCardImage2',\n                    { rules: [{ validator: compareFile3 }] },\n                  ]"}],attrs:{"name":"file","multiple":false,"action":_vm.uploadUrl,"headers":_vm.headers,"file-list":_vm.legalPersonCardImage2,"accept":".jpg,.png"},on:{"change":function (info) { return _vm.handleChange(info, '3'); }}},[_c('a-button',{staticStyle:{"margin-right":"50px"}},[_vm._v(" "+_vm._s(_vm.legalPersonCardImage2.length > 0 ? "重新上传" : "点击上传")+" ")]),(_vm.legalPersonCardImage2.length > 0)?_c('a-button',{on:{"click":function($event){return _vm.download('3')}}},[_vm._v(" 点击预览 ")]):_vm._e()],1)],1)],1)],1),_c('a-col',{attrs:{"span":12}})],1)],1),_c('div',{staticClass:"btn"},[_c('a-button',{staticStyle:{"width":"120px","height":"40px"},attrs:{"type":"primary"},on:{"click":_vm.submit}},[_vm._v(" "+_vm._s(_vm.submitText)+" ")])],1)],1)]),_c('a-modal',{attrs:{"cancel-button-props":{ style: { display: 'none' } },"centered":"","mask-closable":false,"keyboard":false,"ok-text":"确定"},on:{"ok":_vm.handleOk},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c('p',[_vm._v("用户未登录，请重新登录。")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }